/*
Theme Name: NMSB Theme
Theme URI: http://creativepickle.com/
Description: Custom Theme for NMSB
Author: CreativePickle
Version: 1.0
Tags: 
*/

@import url('reset.css');
@import (less) "mixins.less";

/****LAYOUT****/
html, body, #container {height: 100%; width:100%;}


body{
    background:#fff url(images/bg.png) repeat 0 0;
    &.page-template-homepage.php{
        
    }
}

.central{
    width:100%;
    max-width:@centralWidth!important;
    margin-left: auto;
    margin-right:auto;
    padding:0 2% 0 2%!important;
    position:relative;
    }

.tiled{
    &.light-blue{background:url(images/tiles/light-blue.jpg) repeat 0 0;}
    &.dark-blue{background:url(images/tiles/dark-blue.jpg) repeat 0 0;}
    &.beige{background:url(images/tiles/beige.jpg) repeat 0 0;}
    &.stripe-wide{
        background:url(images/tiles/stripe-wide.jpg) repeat-x 0 0;
        &.bottom{background:url(images/tiles/stripe-wide.jpg) repeat-x bottom left;}
        }
    &.stripes{background:url(images/tiles/stripe-lg.jpg) repeat 0 0;}
    }
    
span.separator{
    height:2px;
   
    width:100%;
    display:block;
    background:@blue;
    &.orange{background:@orange;}
    &.short{
        .inline-block();
        width:50%;
        max-width:50px;
        }
    }

#main {
    width:@mainWidth;
	padding:0;
	margin:0px auto;

    }
    
#content{
    width:@mainWidth;
	padding:0px 0 30px 0;
	.bodyfont();
	&.no-pad{
	    padding:0!important;
	    #banner{margin-bottom:0!important;}
	    #main-content{padding:0!important;}
	    }
	}
	
#content-wrap{
   
    }

#main-content{
	padding:10px;
    line-height:1.5;
    ul{
        padding:10px 0 10px 0;
        li{
            position:relative;
            padding:0 0 0 30px;
            margin-bottom:8px;
            i{
                position:absolute;
                top:2px;
                left:0;
                }
            }
        }
    ul.inline{
        li{
            display:inline-block;
            width:30%;
            }
        }
    img{
        border:1px solid @grayMed;
        max-width:100%;
        height:auto;
        &.break{clear:both;}
        &.no-border{border:none!important;}
        }
    .wp-caption-text{
        .goudy(13px);
        .italic();
        color:@blue;
        }
    h1{
        .goudy(50px);
        letter-spacing:.03em;
        color:@orange;
        .italic();
        margin:10px 0 20px 0;
        line-height: 1.2;
        &.no-pad{margin: 0px 0 5px 0;padding:0;}
        }
    h2{
    	line-height: 1.3;
        .raleway(36px);
        letter-spacing:.1em;
        color:@blue;
        .uppercase();
        .bold();
        margin:10px 0 20px 0;
        &.no-pad{margin: 0px 0 5px 0;padding:0;}
        }
    h3{
        .raleway(20px);
        letter-spacing:.1em;
        color:@orange;
        .uppercase();
        .bold();
        margin:10px 0 20px 0;
        }
    h4{
        .goudy(24px);
        letter-spacing:.03em;
        color:@blue;
        margin:10px 0 20px 0;
        }
    &.grid-70{
        padding-right:30px;
        }
    a{
        color:@orange;
        &:hover{color:@blue;}
        }
    }
	
#sidebar{
	padding:0 10px 10px 30px;
	border-left:1px solid @grayLight;
	img{
        display:inline-block;
        max-width:100%;height:auto;
        }
    &.campaign{
    	border:2px solid @grayLight;
    	padding:0;
    	position: relative;
    	top:-100px;
    	z-index: 5;
    	background:#fff;
    	.centered();
    	.gform_wrapper{
    		.inline-block();
    		width:90%!important;
    		ul.gform_fields{
    			li{padding-right:0!important;}
    			}
    		}
    	.gform_footer{
    		.centered();
    		}
    	}
	}

.block-title{
	width:100%;
	height:auto;
	background:@orange;
	color:#fff;
	.raleway(24px);
	font-weight: 300;
	padding:15px;
	background:@orange url(images/chat.svg) no-repeat 20px center;
	background-size: 80px auto;
	padding-left:140px;
	.uppercase();
	text-align: left;
	margin-bottom:20px;
	}
	
ul.subpage-menu{
    width:100%;
    padding:0;
    margin:0 0 40px 0;
    >li{
        display:block;
        margin:0;
        padding:0;
        >a{
            display:block;
            padding:10px;
            border-top: 2px solid @defaultBG;
            .transition(all linear 130ms);
            font-size:20px;
            .text-shadow();
            &:hover{
                background:#f1f1f1;
                padding-left:15px;
                }
            }
            &.current_page_item>a, &.current_page_ancestor>a{
                background:#f1f1f1;
                padding-left:15px;
                }
            &:first-child>a{border-top:none;}
            >ul.children{display:none;}
            &.current_page_item>ul.children, &.current_page_ancestor>ul.children{
                padding:0;
                margin:0;
                display:block;
                >li{
                    >a{
                        .italic();
                        padding:10px;
                        border-top: 2px solid #e6e6e6;
                        .transition(all linear 130ms);
                        font-size:16px;
                        .text-shadow();
                        display:block;
                        &:hover{color:@accent;}
                        }
                         &:first-child>a{border-top:none;}
                         &.current_page_item>a{color:@accent;}
                    }
                }
        }
    }

ul.document-list{
     padding:0!important;
     margin:0px 0 10px 0!important;
    li{
        background:none!important;
        padding-left:0!important;
        margin-bottom:0!important;
        a{
            display:block;
            padding:10px 0 10px 0;
            .bodyfont();
            .uppercase();
            font-weight:300;
            border-bottom:1px solid @content;
            .transition();
            i{
                .inline-block();
                font-size:20px;
                color:@content;
                margin-right:10px;
                position:relative;
                top:2px;
                }
            &:hover{
                color:@content!important;
                background:@content;
                padding-left:10px;
                }
            }
        }
    }

#action{
    padding:120px 0 120px 0!important;
    h5{
        margin-bottom:0;
        &.footer-header{
        	color:@blue;
        	.raleway(40px);
        	.bold();
        	.uppercase();
        	letter-spacing: .04em;
        	font-style: normal;
        	margin-bottom: 25px;
        	}
        }
    }
    
.footer-testimonial-item{
	.raleway(20px);
	line-height: 1.5;
	color:@blue;
	.italic();
	position: relative;
	padding:0 60px 0 60px;
	.quote{
        display:block;
        .goudy(68px);
        color:@orange;
        position:absolute;
        line-height:1;
        &.open{
            top:0;
            left:0;
            }
        &.close{
            top:0;
            right:0;
            
            }    
        }
	}

#footer {
	position: relative;
	width:@mainWidth;
	clear:both;
	border-top:6px solid #fff;
	} 
	
.footer-description{
    .goudy(16px);
    padding:10px;
    color:@blue;
    .italic();
    }
	
.footer-content{
	text-align:center;
	line-height:1.3;
	.raleway(16px);
	letter-spacing:.09em;
	color:#fff;
	.uppercase();
	div{
	    padding:50px 0 20px 0!important;
	    }
	   a{
	       color:#fff;
	       &:hover{
	           color:@orange;
	           }
	       }
	
	}
	
ul.footer-logos{
    margin-top:50px;
    li{
        .inline-block();
        margin:10px;
        max-width:100px;
        img{
        	width:100%;
        	height:auto;
        	}
        }
    }
	
#copyright{
    .centered();
    padding:10px;
    .raleway(9px);
    .uppercase();
    color:@blue;
    background:@blueDark;
    letter-spacing:.3em;
    a{
        color:@blue;
        &:hover{
            color:@orange;
            }
        }
    }
	
.section{
    width:100%;
    position:relative;
    min-height:200px;
    
    h5{
        .goudy(40px);
        .italic();
        color:@orange;
        line-height:1.2;
        margin-bottom:30px;
        span{
            .raleway(40px);
            .bold();
            .uppercase();
            letter-spacing:.07em;
            font-style:normal;
            color:@blue;
            }
        &.on-dark{
            span{
                color:#fff;
                }
            }
        }
    }

.lead-in{
	
    .raleway(18px);
    .uppercase();
    letter-spacing:.05em;
    .bold();
    }
    
.vert-center-wrap{
    display:table;
    height:100%;
    height: inherit;
    >.vert-center{
        display:table-cell;
        vertical-align:middle;
        
        }
    }
	
/**********PAGE STYLES**********/
body.single-campaign{
	.callout{
		font-size:24px;
		}
	}

.callout{
    display:block;
    position:relative;
    padding:0 50px 0 50px;
    .centered();
    .raleway(30px);
    color:@blue;
    .bold();
    line-height:1.5;
    .quote{
        display:block;
        .goudy(68px);
        color:@orange;
        position:absolute;
        line-height:1;
        &.open{
            top:0;
            left:0;
            }
        &.close{
            bottom:0;
            right:0;
            display:none;
            }    
        }
    &.left{
        width:100%;
        max-width:340px;
        float:left;
        margin:0 20px 15px 0;
        text-align:right;
        }
    &.right{
        width:100%;
        max-width:340px;
        float:right;
        text-align:left;
        margin:0 0 15px 20px;
        }
    &.center{
        width:100%;
        border-top:1px solid @blueDark;
        border-bottom:1px solid @blueDark;
        padding:15px 20px 15px 20px;
        margin:10px 0 10px 0;
        .quote{
            display:none!important;
            }
        }
    &.small{
    	font-size:22px;
    	&.right,&.left{max-width: 420px;}
    	}
     }

.image-scaler{
    .scaler();
    min-width:100px;
    height:auto;
    }

/***********HEADER and NAVIGATION*********/
@menuLink: #666;
@menuLinkHover: #333;

#main-header{
    min-height:100px;
    background:transparent;
    padding-bottom:20px;
    #top-bar{
        background:@blue;
        color:@blueLight;
        padding:10px 0 10px 0;
        line-height:1;
        text-align:right;
        margin-bottom:20px;
        span{
            .inline-block();
            vertical-align:middle;
            margin-right:10px;
            .raleway(13px);
            color:#fff;
            letter-spacing:.03em;
            i{color:@blueLight;.inline-block();margin-right:8px;}
            a{
                color:#fff;
                &:hover{
                    color:@orange;
                    }
                }
            }
        }
    span.tagline{
        .goudy(18px);
        display:block;
        color:@blue;
        .italic();
        margin-top:6px;
        }
}

#nav{
    
}

#menu-main-navigation-1{
    list-style:none;
    padding:30px 0 0 0;
    text-align:right;
    >li{
        .inline-block();
        margin-right:2px;
        position:relative;
        
        &.current_page_item>a, &.current-page-ancestor>a{color:@orange!important;}
        &:last-child a{padding-right:0;}
        >a{
            letter-spacing:.02em;
            display:block;
            padding:10px 15px 10px 15px;
            color:@accent;
            text-align:left;
            border-left:3px solid #fff;
            .goudy(17px);
            color:@blue;
            .transition();
            .italic();
            span{
                display:block;
                .uppercase();
                font-style:normal;
                .raleway(15px);
                }
            }
        a:hover{
            color:@orange;
            border-left:3px solid @orange;;
            }
        }
    }
 
ul#menu-social-navigation, ul#menu-social-navigation-1{
    .inline-block();
    vertical-align:middle;
    margin:0;
    padding:2px 0 0 0;
    li{
        .inline-block();
        color:@orange;
        font-size:13px;
        padding:0;
        margin:0;
        position:relative;
        margin:0 1px 0 1px;
        &:hover{color:@blueLight;}
        a{
            display:block;
            text-align:left;
            text-indent:-9999px;
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
            }
        }
    }  
    
ul#menu-social-navigation-1{
    margin-top:30px;
    li{
        font-size:30px;
        margin:0 4px 0 4px;
        }
    }
/***********HEADER STYLES*******/

h1{
    color:#333;
    font-size:36px;
    padding:0 0 12px 0;
    }
    
h2{
    font-size:30px;
    color:#333;
    padding:0 0 12px 0;
    }
    
h3{
    font-size:24px;
    color:#333;
    padding:0 0 8px 0;
    }
    
h4{
    font-size:18px;
    padding:0 0 8px 0;
    }
    
/***********BUTTONS***********/

a.button{
    .bodyfont();
    display:inline-block;
    .raleway(16px);
    .uppercase();
    position:relative;
    padding:10px 0 10px 0;
    color:@blue!important;
    position:relative;
    .transition();
    .centered();
    margin-top:15px;
    letter-spacing:.05em;
    font-weight:400;
    i{
        margin-left:5px;
        position:relative;
        z-index:1;
        color:@blue!important;
        }
    .link{
        position:relative;
        z-index:1;
        }
    .bg{
        width:100%;
        height:2px;
        background:@blue;
        .transition();
        position:absolute;
        top:0;
        left:0;
        display:block;
        z-index:0;
        }
    &:hover{
        color:#fff!important;
       padding:10px;
       i{color:#fff!important;}
        .bg{
            height:100%;
            }
        }
    &.orange{
        .bg{
            background:@orange!important;
            }
        }
    &.white{
         .bg{
            background:#fff;
            }
        &:hover{
            color:@blueDark!important;
            i{color:@blueDark!important;}
            }
        }
    &.large{
        .raleway(45px);
        .bold();
        i{margin-left:15px;}
        .bg{
            height:2px!important;
            }
        &:hover{
            color:@orange!important;
            i{color:@orange!important;}
            }
        }
    &.full{
        display:block;
        background:@blue;
        color:#fff!important;
        i{color:#fff!important;}
        .bg{
            display:none;
            }
        &:hover{
            background:#f8b546;
            }
        }
    }

a.block{
    width:100%;
    height:100%;
    display:block;
    position:absolute;
    top:0;
    left:0;
    z-index:500;
    }

/*********HOMEPAGE**********/


#banner{
    &.homepage{
    border-bottom:1px solid #fff;
    position:relative;
   height:580px;
   .scaler();
   .overlay{
       position:absolute;
       top:0;
       left:0;
       background:rgba(0,0,0,0.3);
       width:100%;
       height:100%;
       }
   .central{
       .centered();
       }
    }
    &.subpage{
        background:url(images/tiles/dark-blue.jpg) repeat 0 0;
        padding:60px 0 60px 0;
        margin-bottom:0!important;
        border-bottom:6px solid #fff;
        height:auto;
        .centered();
        h1{
            .serif();
        color:#fff;
        .raleway(60px);
        letter-spacing:.08em;
        .uppercase();
        .bold();
        .text-shadow();
        padding-left:30px!important;
        padding-right:30px!important;
        }
        h5{
            .raleway(24px);
            .bold();
            letter-spacing:.08em;
            .uppercase();
            color:@orange;
            .text-shadow();
            }
        }
    &.attorney{
        
        padding:0!important;
        .image{
            line-height:1!important;
            img{max-width:100%;height:auto;border:none;border-right:3px solid #fff;border-left:3px solid #fff;display:block;}
            }
        .title{
            text-align:left;
            .text-shadow();
            padding:100px 0 0 30px;
            h1{
                text-align:left;
                font-size:36px;
                padding-left:0!important;
                margin-bottom:0!important;
                }
            span.official-title{
                display:block;
                color:#fff;
                font-size:24px;
                .italic();
                margin-bottom:20px;
                }
            span.contact{
                display:block;
                color:#fff;
                margin-bottom:8px;
                i{
                    color:@orange;
                    margin-right:10px;
                    }
                a{
                    color:#fff;
                    &:hover{
                        color:@orange;
                        }
                    }
                }
            }
        }
    }
    

    
.banner-content{
    color:#fff;
    position: relative;
      top: 70%;
      transform: translateY(-50%);
      h1{
      		display: inline-block;
			color:#fff;
            .raleway(26px);
            .uppercase();
            letter-spacing: .10em;
            font-weight:400;
            border-top:2px solid @orange;
            margin-top:20px;
            padding-top:20px;

      	}
    }

.hometitle{
    color:#fff;
    .text-shadow();
    .inline-block();
    width:70%;
    .goudy(75px);
    .italic();
    line-height:1!important;
    span{
        .raleway(75px);
        .bold();
        .uppercase();
        display:block;
        font-style:normal;
        letter-spacing:.10em;
        
        }
    }
    
#about{
    background:url(images/tiles/dark-blue.jpg) repeat 0 0;
    padding:0;
    border-top:5px solid #fff;
    border-bottom:5px solid #fff;
    line-height:1.5;
    >div{
        min-height:240px;
        &.left{
            background:transparent;
            text-align:right;
            padding:50px 50px 50px 50px;
            position:relative;
           h5{
               margin-top:120px;
               }
            }
        &.right{
            text-align:left;
            padding:50px 15% 50px 50px;
            }
        }
    }

#answers{
    padding:100px 0 100px 0;
    #icon-wrap{
        .inline-block();
        width:90%;
        margin-top:70px;
        position:relative;
        margin-bottom:75px;
        
        }
    h5{
        .raleway(45px);
        .uppercase();
        color:@blue;
        .bold();
        font-style:normal;
        letter-spacing:.08em;
        }
    }    

.answer-item{
    padding-bottom:30px;
    margin-bottom:30px;
    border-bottom:2px solid @grayLight;
    text-align:left;
    .image{
        img{
            width:100%;
            max-width:300px;
            height:auto;
            .inline-block();
            }
        }
    .text{
        h6{
            color:@orange;
            .goudy(40px);
            .italic();
            margin-bottom:20px;
            }
        a.button{
            span.bg{
                background:@orange;
                }
            }
        }
    }
    
.practice-area-item{
	position:relative;
	background:@grayLight;
	.rounded-corners(18px);
	width:100%;
	max-width:300px;
	padding:60px 20px 20px 20px;
	.inline-block();
	margin:0 20px 60px 20px;
	min-height:550px;
	.transition(all linear 200ms);
	.scale(1);
	.text{
		position: relative;
		z-index: 10;
		h6{
			.raleway(24px);
			.uppercase();
			.bold();
			color:@blue;
			margin-bottom: 25px;
			}
		a.link{
			display: block;
			color:@orange;
			.bold();
			i{margin-left:8px;}
			.transition(all linear 140ms);
		
			&:hover{
				color:@blueLight!important;
				i{margin-left: 12px;}
				}
			}
		}
	.icon{
		.inline-block();
		.rounded-corners(50%);
		width:140px;
		height:140px;
		background-size:auto 60%!important;
		position: absolute;
		top:-70px;
		left:50%;
		
		transform:translateX(-50%);
		}
	&:hover{
		.scale(1.04);
		}
	&.page{
		padding-top:20px;
		margin:20px 40px 20px 40px;
		.transition(all linear 200ms);
		.scale(1);
		.icon{
			top:0;
			left:0;
			transform:translateX(0);
			width:120px;
			height:120px;
			position: relative;
			margin-bottom:10px;
			}
		.text{
			h6{
				font-size: 20px;
				}
			}
		background:transparent;
		min-height: 0;
		max-width: 200px;
		&:hover{
			.scale(1.1);
			.text h6{color:@orange;}
			}
		}
	}
    
.section#attorneys{
    padding:0!important;
    }
    
.attorney-item{
    border:2px solid #fff;
    padding:0!important;
    position:relative;
    overflow:hidden;
    img{width:100%;height:auto;display:block;position:relative;z-index:0;}
    .info-wrap,.title-wrap{
        position:absolute;
        width:100%;
        height:100%;
        .transition(all linear 200ms);
        z-index:10;
        .centered();
        }
    .info-wrap{
        left:100%;
        top:0;
        background:rgba(49, 103, 127, 0.95);
        .centered();
        color:#fff;
        
        font-size:18px;
        .italic();
        .text-shadow();
        >div{
            position:relative;
            padding:15px;
             position: relative;
              top: 50%;
              transform: translateY(-50%);
            }
        a{
            color:@orange;
            font-size:28px;
            .transition();
            &:hover{
                color:@orange;
                
                }
            }
        span{
                display:block;
                &.name{
                    .raleway(16px);
                    .uppercase();
                    color:@orange;
                    letter-spacing:.09em;
                    margin-bottom:5px;
                    font-style:normal;
                    }
                &.official-title{
                    .goudy(18px);
                    .italic();
                    color:#fff;
                    margin-bottom:15px;
                    }
                }
        }
    .title-wrap{
        left:0;
        top:0;
        .title{
            .text-shadow();
            width:100%;
            padding:10px;
            position:absolute;
            bottom:0;
            left:0;
            background:rgba(142, 172, 172, 0.9);
            color:#fff;
            span{
                display:block;
                &.name{
                    .raleway(16px);
                    .uppercase();
                    color:@blue;
                    letter-spacing:.09em;
                    .bold();
                    margin-bottom:5px;
                    }
                &.official-title{
                    .goudy(18px);
                    .italic();
                    color:#fff;
                    }
                }
            }
        }
    &:hover{
        .title-wrap{left:-100%;}
        .info-wrap{left:0;}
        }
    &.page{
        .inline-block();
        width:90%;
        max-width:300px;
        margin:10px;
        }
    }
    
#testimony{
    padding:120px 0 120px 0;
    border-bottom:6px solid #fff;
    .centered();
    .testimonial-item{
        .italic();
        padding:0 5% 0 5%;
        outline:none;
        font-size:19px;
        line-height:1.6;
        }
    }
    
ul.slick-dots{
    display:block;
    .centered();
    width:100%;
    padding:0!important;
    margin:0!important;
    position:absolute;
    bottom:-50px;
    z-index:50;
    left:0;
    li{
        .inline-block();
        padding:0;
        margin:0 3px 0 3px;
        button{
            text-align:left;
            background:#fff;
            width:12px;
            height:12px;
            border:none;
            outline:none;
            display:block;
           border:2px solid @grayLight;
            .transition(all linear 150ms);
            text-align:left;
            text-indent:-9999px;
            padding:0!important;
            &:hover{
                cursor:pointer;
                background:@orange;
                border:2px solid @orange;
                }
            }
        &.slick-active{
            button{
                background:@orange!important;
                border:2px solid @orange;
                }
            }
        }
    }
    
/**********ABOUT-PAGE**********/

.about-image{
    padding-left:0!important;
    }
    
.about-callout{
    padding-left:90px!important;
    padding-top:50px;
    text-align:left!important;
    line-height:1.6;
    span.quote.open{
        left:35px!important;
        top:50px;
        }
    }
    
/**********ATTORNEY PAGES********/

.attorney-navigation{
    margin-top:25px;
    clear:both;
    .centered();
    >div{
        .inline-block();
        .raleway(18px);
        color:@blue;
        .uppercase();
        border-top:3px solid @orange;
        padding-top:8px;
        a{
            .inline-block();
            vertical-align:middle;
            color:@blue;
            &:hover{
                color:@orange;
                }
            }
        span{
            .inline-block();
            vertical-align:middle;
            margin:0 10px 0 10px;
            letter-spacing:.09em;
            }
        }
    }

/**********ITEMS and WIDGETS**************/

.item{
    width:100%;
    margin-bottom:30px;
    padding-bottom:30px;
    border-bottom:1px solid @content;
}

.widget{
    margin-bottom:30px;
    padding-bottom:30px;
    border-bottom:1px solid @grayMed;
    h5.widget-title{
         .raleway(20px);
        letter-spacing:.1em;
        color:@orange;
        .uppercase();
        .bold();
        margin:10px 0 20px 0;
    }
    &.list-widget{
        ul{
            li{
                position:relative;
                padding-left:30px;
                margin-bottom:10px;
                font-size:16px;
                i{
                    color:@blue;
                    position:absolute;
                    top:-2px;
                    left:0;
                    }
                a{
                    color:#468dab!important;
                    &:hover{
                        color:@orange!important;
                        }
                    }
                }
            }
        }
    ul.widget-list{
        li{
            a{
                .raleway(16px);
                display:block;
                padding:15px 10px 15px 0;
                border-top:1px solid @grayLight;
                .uppercase();
                color:@blue;
                .transition();
                &:hover{
                    background:@grayLight;
                    padding:15px 10px 15px 10px;
                    color:@blueDark;
                    }
                }
            &:last-child a{
                border-bottom:1px solid @grayLight;
                }
            }
        }
    &#contact-details{
        .uppercase();
        .raleway(16px);
        line-height:1.4;
        i{margin-right:12px;color:@blueLight;}
        a{
            color:@blue;
            &:hover{color:@orange;}
            }
        }
    .form-desc{
        .raleway(15px);
        color:@blue;
        }
}
 
/********TEAM PAGE******/
#staff-wrap{
    margin-top:50px;
    }

.item.staff{
    padding:0;
    border:none;
    span.contact{
        display:block;
        margin-bottom:15px;
        a{
            padding:10px;
            color:@blueLight;
            .uppercase();
            i{margin-right:8px;}
            &:hover{
                color:@orange;
                }
            }
        }
    .trigger{
    h3{
        color:@blueLight!important;
        border-bottom:1px solid @grayMed;
        padding-bottom:25px;
        
        span.official-title{
            .goudy(20px);
            text-transform:none;
            .italic();
            .transition(all liner 200ms);
            letter-spacing:0;
            }
        i{
            .inline-block();
            vertical-align:middle;
            margin-left:15px;
            .transition(all linear 200ms);
            .rotate(0deg);
            }
        &:hover{
            cursor:pointer;
            h3{color:@blueLight!important;}
            i{
                .rotate(90deg);
                }
            }
        }
    &.active{
        cursor:pointer;
        h3{
            color:@blueLight!important;
            i{
                .rotate(90deg);
                }
            }
        }
    }
    .block{
        padding-top:0px;
        .staff-thumb{
            margin:0 0 20px 0;
            img{
                .inline-block();
                width:100%;
                max-width:240px!important;
                height:auto;
                border:1px solid rgba(196, 198, 201, 1.000);
                }
            }
        }
    }
    
/*******RESOURCES*******/

.item.resource{
    border:none;
    padding:30px;
    .centered();
    background:@grayLight;
    a.button{
        i.fa-long-arrow-right{display:none;}
       
        }
    }
    
/*********PRACTICE AREAS*******/
.tax-nav{
    padding:20px 0 40px 0!important;
    .centered();
    li{
        i{display:none;}
        padding:0!important;
        margin:0 6px 11px 0!important;
        .inline-block();
        a{
            display:block;
            border:1px solid @blueDark;
            padding:10px;
            .raleway(13px);
            .bold();
            color:#fff!important;
            background:@blue;
            .uppercase();
            .transition();
            &:hover{
                color:@blue!important;
                background:#fff;
                }
            }
        }
    }

.item.practice{
    padding:0;
    border:none;
    position:relative;
    &.right-toggle{
            .trigger h3 i{
                position:absolute;
                top:3px;
                right:0;
                }
            }
    .trigger{
    h3{
        color:#468dab!important;
        border-bottom:1px solid @grayLight;
        font-size:22px!important;
        padding-bottom:25px;
        padding-right:25px;
        span{
            margin-top:10px;
            display:block;
            text-transform:none;
            .raleway(22px);
            font-weight:300;
            }
        i{
            .inline-block();
            vertical-align:middle;
            margin-left:15px;
            .transition(all linear 200ms);
            .rotate(0deg);
            }
        
        &:hover{
            cursor:pointer;
            color:@orange!important;
            i{
                .rotate(90deg);
                }
            }
        }
    &.active{
        cursor:pointer;
        h3{
            color:@orange!important;
            span{color:@blueDark;}
            i{
                .rotate(90deg);
                }
            }
        }
    }
    .block{
        padding-top:0px;
        }
    }
    
.pdf-box{
    padding:30px 20px 30px 20px!important;
    background:@grayLight;
    border:1px solid @grayMed;
    margin-bottom:35px;
    .image{
        img{
            display:block;
            width:100%;
            height:auto;
            }
        }
    a.button{
        i.fa-long-arrow-right{display:none;}
        }
    }
    
/*********BLOG*********/

.item.blog{
    border-bottom:1px solid @grayMed;
    .thumbnail-container{
        width:100%;
        height:280px;
        border:1px solid @grayMed;
        margin-bottom:25px;
        }
    h3.blogtitle{
        margin-bottom:8px!important;
        padding-bottom:0!important;
        }
    h5.date{
        .goudy(18px);
        color:@blue;
        .italic();
        margin-bottom:20px;
        }
    a.readmore{display:none;}
    }
    
/********TESTIMONY*********/

.item.testimonial{
    background:@grayLight;
    .italic();
    padding:20px;
    border:1px solid @grayMed;
    }
    
ul#testimony-nav{
    padding:0!important;
    .centered();
    li{
        padding:0!important;
        margin:10px!important;
        .inline-block();
        position:relative;
        width:100px;
        img{width:100%;height:auto;display:block;margin-bottom:10px;border:1px solid @grayMed;width:100%;height:auto;}
        i{display:none;}
        .goudy(14px);
        .italic();
        .transition();
        &:hover{
            .scale(1.1);
            }
        }
    }

/********ALIGNMENT********/

/* Alignment */
.alignleft {
    display: inline;
	float: left;
	margin-right: 1.625em;
}
.alignright {
	display: inline;
	float: right;
	margin-left: 1.625em;
}
.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft,
img.alignright,
img.aligncenter {
    margin-bottom: 1.625em;
}	

.clear{
    clear:both;
    }  

.wp-caption-text{
    font-size:12px;
    .italic();
    }
    
figure{
    -webkit-margin-before: 0;
-webkit-margin-after: 0;
-webkit-margin-start: 0;
-webkit-margin-end: 0;
    }
    
.fixed-bg{
    background-attachment:fixed!important;
    }
    
.scaler{
    position:relative;
    -webkit-background-size: cover!important;
    -moz-background-size: cover!important;
    -o-background-size: cover!important;
    background-size: cover!important;
}

.centered{
    text-align:center;
    }